
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const Navbar = (props) => {
  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
    <div className="container-fluid">
      <Link className="navbar-brand" to="/">{props.title}</Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg- 0">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-disabled="false" to="/about">About</Link>
          </li>
        </ul>
        <div className="d-flex">
          <div className="bg-primary rounded mx-2" onClick={()=>{props.toggleColor('primary')}} style={{height:'30px',width:'30px',cursor:'pointer'}} ></div>
          <div className="bg-danger rounded mx-2" onClick={()=>{props.toggleColor('danger')}} style={{height:'30px',width:'30px',cursor:'pointer'}} ></div>
          <div className="bg-success rounded mx-2" onClick={()=>{props.toggleColor('success')}} style={{height:'30px',width:'30px',cursor:'pointer'}} ></div>
          <div className="bg-warning rounded mx-2" onClick={()=>{props.toggleColor('warning')}} style={{height:'30px',width:'30px',cursor:'pointer'}} ></div>
        </div>
          <div className={`form-check form-switch text-${props.mode==='dark'?'light':'dark'}`}>
            <input className="form-check-input" type="checkbox" onClick={()=>{props.toggleMode()}}  id="flexSwitchCheckDefault"/>
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Enable  Dark Mode</label>
          </div>

      </div>
    </div>
    </nav >
  )
}

export default Navbar
//setting proptype sytax for error handling
Navbar.propTypes={
  title:PropTypes.string // to make it required chaange it to PropTypes.string.isRequired 
}
//setting default props values
Navbar.defaultProps={
  title:"textUtils"
}